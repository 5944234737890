import React from "react"
import { graphql, Link } from "gatsby"
import { isLoggedIn } from "../services/auth"
import logo from "../images/logo.png"
import LayoutFull from "../components/layout-full"

export default function Home({ data }) {
  return (
    <LayoutFull>
      <img
        className="mx-auto w-full sm:w-3/4 h-auto"
        src={logo}
        alt={`${data.site.siteMetadata.title} Logo`}
      />

      <h2 className="mt-12 text-center text-4xl leading-9 font-extrabold text-gray-900">
        Homeowner Portal
      </h2>

      <p className="mt-2 text-center text-base leading-5 text-gray-600">
        Welcome to the {data.site.siteMetadata.title} homeowner portal. The
        portal contains all the information about your new home, including your
        digital brochure and full appliance guides.
      </p>

      <div className="mt-12">
        {isLoggedIn() ? (
          <>
            <Link
              to={"/portal"}
              className="w-full inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus:bg-primary-400 transition duration-150 ease-in-out"
            >
              Go to the portal
            </Link>
          </>
        ) : (
          <>
            <Link
              to={"/portal/login"}
              className="w-full inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus:bg-primary-400 transition duration-150 ease-in-out"
            >
              Log in
            </Link>
          </>
        )}
      </div>
    </LayoutFull>
  )
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
